<div class="main">
    <div class="top container-fluid text-center p-3">
        <img src="{{staticfolder}}/assets/img/logocongreso2025.png" class="img-fluid img-logo" alt="">
    </div>
    <div class="container text-center">
        <h1>Registro de Participantes</h1>
        <p>
            Para facilitar tu acceso al evento, debes registrarte y obtener tu código QR. <br>
            Para continuar, selecciona la opción que corresponda:
        </p>
        <div class="container-fluid">
            <button *ngIf="step == 0" class="btn btn-primary m-3 p-3 btn-select" (click)="changeStep(2)">Soy Público en General</button>
            <button *ngIf="step == 0" class="btn btn-primary m-3 p-3 btn-select" (click)="changeStep(1)">Soy Estudiante o Catedrático de CEYESOV</button>

            <ng-container *ngIf="step == 0">
                <p>
                    Ya te registraste y no recibiste tu código, da click aquí:
                </p>
                <button class="btn btn-primary m-1 p-1 btn-select" (click)="changeStep(5)">Recibir mi código</button>
            </ng-container>

            <h3 *ngIf="step == 1">Soy Estudiante o Catedrático de Ceyesov</h3>
            <h3 *ngIf="step == 2">Soy Público en General</h3>
            <button *ngIf="step > 0" class="btn btn-primary m-3" (click)="changeStep(0)">Regresar</button>
            <p *ngIf="step == 1">
                Si cuentas con acceso a CEYESYS, accede y registra tu boleto en el menú Eventos > Boletos. <br>
                Si no cuentas con acceso a CEYESYS, acercate al departamento de Informes para que te apoyen con el registro o te orienten donde puedes conseguirlo.
            </p>
        </div>
        <div *ngIf="step == 2">
            <h3>Captura tus datos</h3>
            <form class="p-2" #participantForm="ngForm" (ngSubmit)="onSubmit(participantForm)">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group">
                        <label for="name">Tu nombre</label>
                        <input type="text"
                            class="form-control" name="name" id="name" aria-describedby="name" placeholder="Nombre" [(ngModel)]="participant.name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                        <label for="name">Tu apellido</label>
                        <input type="text"
                            class="form-control" name="last_names" id="last_names" aria-describedby="last_names" placeholder="Apellidos" [(ngModel)]="participant.last_names">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label class="small" for="title">Fecha de Nacimiento</label>
                        <input class="form-control form-control-sm" type="date" name="birth_date" #birth_date="ngModel" [(ngModel)]="participant.birth_date">
                        <small *ngIf="!birth_date.valid && birth_date.touched" class="invalid-feedback d-block">Fecha de nacimiento inválida</small>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="small" for="title">Email</label>
                        <input class="form-control form-control-sm" type="email" name="email" #email="ngModel" [(ngModel)]="participant.email" [email]="true">
                        <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block"> {{participantForm.errors}} Captura un Email válido</small>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="small" for="title">Genero</label>
                        <select class='form-control form-control-sm select-option' name="gender" #gender="ngModel" [(ngModel)]="participant.gender">
                            <option value="null">-- Selecciona una opción</option>
                            <option class='option' value="M">Masculino</option>
                            <option class='option' value="F">Femenino</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label class="small" for="title">Pais</label>
                        <select class='form-control form-control-sm select-option' name="country" #country="ngModel" [(ngModel)]="participant.country" (ngModelChange)="countryChanged($event)">
                            <option [ngValue]="undefined">-- Selecciona una opción</option>
                            <option class='option' *ngFor='let country of countries' [ngValue]="country.id">{{ country.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="small" for="title">Ciudad</label>
                        <select class='form-control form-control-sm' name="city" #city="ngModel" [(ngModel)]="participant.city">
                            <option [ngValue]="undefined">-- Selecciona una opción</option>
                            <option class='option' *ngFor='let city of cities' [ngValue]="city.id">{{ city.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required (resolved)="onCaptchaResolved($event)"></re-captcha>
                        <!-- <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback"> -->
                        <!-- <div *ngIf="recaptcha.errors?.['required']">This field is required.</div> -->
                        <!-- </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col my-3 text-right">
                        <button type="submit" class="btn btn-primary mb-2 mr-2" [disabled]="participantForm.invalid" [disabled]="working"> Guardar</button>
                        <div class="ball-clip-rotate" *ngIf="working"><div></div></div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="step == 3">
            <h3>Bienvenido {{ participant.name }} {{ participant.last_names }}</h3>
            <h5>Registra tu boleto</h5>
            <form class="p-2" #ticketForm="ngForm" (ngSubmit)="onSubmitTicket(ticketForm)">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group">
                        <label for="ticket_number">Número de Boleto:</label>
                        <input type="text"
                            class="form-control" name="ticket_number" id="ticket_number" aria-describedby="ticket_number" placeholder="No. Boleto" (change)="ceros()" [(ngModel)]="ticket.paper_ticket">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                        <label for="ticketkey">Clave de Registro:</label>
                        <input type="text"
                            class="form-control" name="ticket_key" id="ticket_key" aria-describedby="ticket_key" placeholder="Clave de Registro" [(ngModel)]="ticket.ticket_key">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <re-captcha id="recaptcha2" name="recaptcha2" #recaptcha2="ngModel" [(ngModel)]="token2" required (resolved)="onCaptchaResolved2($event)"></re-captcha>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col my-3 text-right">
                        <button type="submit" class="btn btn-primary mb-2 mr-2" [disabled]="ticketForm.invalid" [disabled]="working"> Registrar</button>
                        <div class="ball-clip-rotate" *ngIf="working"><div></div></div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="step == 4">
            <h3>Excelente {{ participant.name }} {{ participant.last_names }}</h3>
            <h5>Registro Exitoso, el boleto digital ha sido enviado a tu correo registrado {{ participant.email }}</h5>
            <div class="container-fluid text-center">
                <img src="{{ phisicalTicket.ticket_image }}" alt="">
                <button class="btn btn-primary" (click)="descargarImagen(phisicalTicket.ticket_image)">Descargar Boleto</button>
            </div>
        </div>
        <div *ngIf="step == 5">
            <h5>Cuál es tu Boleto</h5>
            <form class="p-2" #ticketForgotForm="ngForm" (ngSubmit)="onSubmitTicketForgot(ticketForgotForm)">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group">
                        <label for="ticket_number">Número de Boleto:</label>
                        <input type="text"
                            class="form-control" name="ticket_number" id="ticket_number" aria-describedby="ticket_number" placeholder="No. Boleto" (change)="ceros()" [(ngModel)]="ticket.paper_ticket">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                        <label for="ticketkey">Clave de Registro:</label>
                        <input type="text"
                            class="form-control" name="ticket_key" id="ticket_key" aria-describedby="ticket_key" placeholder="Clave de Registro" [(ngModel)]="ticket.ticket_key">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <re-captcha id="recaptcha3" name="recaptcha3" #recaptcha3="ngModel" [(ngModel)]="token3" required (resolved)="onCaptchaResolved3($event)"></re-captcha>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col my-3 text-right">
                        <button type="submit" class="btn btn-secondary mb-2 mr-2" [disabled]="ticketForgotForm.invalid" [disabled]="working"> Reenviar Boleto</button>
                        <div class="ball-clip-rotate" *ngIf="working"><div></div></div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>